import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c8058486 = () => interopDefault(import('../pages/401.vue' /* webpackChunkName: "pages/401" */))
const _c7b0f780 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _45b87bf4 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _1ce7b532 = () => interopDefault(import('../pages/announcement/index.vue' /* webpackChunkName: "pages/announcement/index" */))
const _03694a4c = () => interopDefault(import('../pages/approved-partner.vue' /* webpackChunkName: "pages/approved-partner" */))
const _3c205a22 = () => interopDefault(import('../pages/buen-fin.vue' /* webpackChunkName: "pages/buen-fin" */))
const _260c6213 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _6a12dd5a = () => interopDefault(import('../pages/customer-benefits.vue' /* webpackChunkName: "pages/customer-benefits" */))
const _7f779b1c = () => interopDefault(import('../pages/hotsale.vue' /* webpackChunkName: "pages/hotsale" */))
const _2d08ba28 = () => interopDefault(import('../pages/localflex.vue' /* webpackChunkName: "pages/localflex" */))
const _9e10c710 = () => interopDefault(import('../pages/outlet/index.vue' /* webpackChunkName: "pages/outlet/index" */))
const _4aa327bf = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4d51ec60 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _7c804626 = () => interopDefault(import('../pages/shopping-cart.vue' /* webpackChunkName: "pages/shopping-cart" */))
const _3b0fe710 = () => interopDefault(import('../pages/social-auth.vue' /* webpackChunkName: "pages/social-auth" */))
const _070c15c2 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _199c4aa0 = () => interopDefault(import('../pages/visas-and-restrictions.vue' /* webpackChunkName: "pages/visas-and-restrictions" */))
const _656a01a5 = () => interopDefault(import('../pages/wheel-of-fortune.vue' /* webpackChunkName: "pages/wheel-of-fortune" */))
const _1d8a15d7 = () => interopDefault(import('../pages/why-choose-us.vue' /* webpackChunkName: "pages/why-choose-us" */))
const _4de1699c = () => interopDefault(import('../pages/c/mountains-in-mexico.vue' /* webpackChunkName: "pages/c/mountains-in-mexico" */))
const _b5304faa = () => interopDefault(import('../pages/landing/tours-en-vancouver-con-vuelos-desde-guadalajara.vue' /* webpackChunkName: "pages/landing/tours-en-vancouver-con-vuelos-desde-guadalajara" */))
const _4a9870de = () => interopDefault(import('../pages/landingz/caza-de-auroras-grupo-en-espanol.vue' /* webpackChunkName: "pages/landingz/caza-de-auroras-grupo-en-espanol" */))
const _2f4292d6 = () => interopDefault(import('../pages/f/components/availability/index.vue' /* webpackChunkName: "pages/f/components/availability/index" */))
const _57761426 = () => interopDefault(import('../pages/f/components/availability/components/card.vue' /* webpackChunkName: "pages/f/components/availability/components/card" */))
const _4e6d5248 = () => interopDefault(import('../pages/user/profile/_tab.vue' /* webpackChunkName: "pages/user/profile/_tab" */))
const _6d9bcd5c = () => interopDefault(import('../pages/user/reservation/_code/edit.vue' /* webpackChunkName: "pages/user/reservation/_code/edit" */))
const _72de9a6c = () => interopDefault(import('../pages/public/reservation/_code/_tab.vue' /* webpackChunkName: "pages/public/reservation/_code/_tab" */))
const _3e140828 = () => interopDefault(import('../pages/user/reservation/_code/_tab.vue' /* webpackChunkName: "pages/user/reservation/_code/_tab" */))
const _1b3113ea = () => interopDefault(import('../pages/announcement/_slug.vue' /* webpackChunkName: "pages/announcement/_slug" */))
const _08f0145d = () => interopDefault(import('../pages/f/_permalink.vue' /* webpackChunkName: "pages/f/_permalink" */))
const _67d85fca = () => interopDefault(import('../pages/h/_permalink.vue' /* webpackChunkName: "pages/h/_permalink" */))
const _6ad0ac8e = () => interopDefault(import('../pages/invitation/_referral_code.vue' /* webpackChunkName: "pages/invitation/_referral_code" */))
const _525b4797 = () => interopDefault(import('../pages/l/_permalink.vue' /* webpackChunkName: "pages/l/_permalink" */))
const _34a29417 = () => interopDefault(import('../pages/magic-link/_token.vue' /* webpackChunkName: "pages/magic-link/_token" */))
const _bbb7ecd2 = () => interopDefault(import('../pages/outlet/_test.vue' /* webpackChunkName: "pages/outlet/_test" */))
const _400026b3 = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _57ad936e = () => interopDefault(import('../pages/permalink/_slug.vue' /* webpackChunkName: "pages/permalink/_slug" */))
const _26f8912e = () => interopDefault(import('../pages/landing/_permalink/_test.vue' /* webpackChunkName: "pages/landing/_permalink/_test" */))
const _540d2ada = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1158b032 = () => interopDefault(import('../pages/_influencer/aventura-rio-nilo.vue' /* webpackChunkName: "pages/_influencer/aventura-rio-nilo" */))
const _87a4ecd4 = () => interopDefault(import('../pages/_influencer/explorando-turquia-con-vuelo.vue' /* webpackChunkName: "pages/_influencer/explorando-turquia-con-vuelo" */))
const _31e44d8f = () => interopDefault(import('../pages/_influencer/lo-mejor-peru.vue' /* webpackChunkName: "pages/_influencer/lo-mejor-peru" */))
const _42c7805c = () => interopDefault(import('../pages/_influencer/noches-auroras-boreales-con-vuelo.vue' /* webpackChunkName: "pages/_influencer/noches-auroras-boreales-con-vuelo" */))
const _072fef8c = () => interopDefault(import('../pages/_influencer/salkantay-trek.vue' /* webpackChunkName: "pages/_influencer/salkantay-trek" */))
const _6919b56d = () => interopDefault(import('../pages/_influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia.vue' /* webpackChunkName: "pages/_influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia" */))
const _388a53b1 = () => interopDefault(import('../components/pages/search/country/index' /* webpackChunkName: "" */))
const _a5884c36 = () => interopDefault(import('../components/pages/checkout-layaway/index.vue' /* webpackChunkName: "" */))
const _054a5749 = () => interopDefault(import('../components/pages/experience-package/ppcv1.vue' /* webpackChunkName: "" */))
const _437551fd = () => interopDefault(import('../components/pages/experience-package/index.vue' /* webpackChunkName: "" */))
const _2f558d49 = () => interopDefault(import('../components/pages/phocuswright/index.vue' /* webpackChunkName: "" */))
const _e5a8ce66 = () => interopDefault(import('../components/pages/outlet-viaja-vuela/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/401",
    component: _c8058486,
    name: "401"
  }, {
    path: "/404",
    component: _c7b0f780,
    name: "404"
  }, {
    path: "/about-us",
    component: _45b87bf4,
    name: "about-us"
  }, {
    path: "/announcement",
    component: _1ce7b532,
    name: "announcement"
  }, {
    path: "/approved-partner",
    component: _03694a4c,
    name: "approved-partner"
  }, {
    path: "/buen-fin",
    component: _3c205a22,
    name: "buen-fin"
  }, {
    path: "/contact-us",
    component: _260c6213,
    name: "contact-us"
  }, {
    path: "/customer-benefits",
    component: _6a12dd5a,
    name: "customer-benefits"
  }, {
    path: "/hotsale",
    component: _7f779b1c,
    name: "hotsale"
  }, {
    path: "/localflex",
    component: _2d08ba28,
    name: "localflex"
  }, {
    path: "/outlet",
    component: _9e10c710,
    name: "outlet"
  }, {
    path: "/privacy-policy",
    component: _4aa327bf,
    name: "privacy-policy"
  }, {
    path: "/search",
    component: _4d51ec60,
    name: "search"
  }, {
    path: "/shopping-cart",
    component: _7c804626,
    name: "shopping-cart"
  }, {
    path: "/social-auth",
    component: _3b0fe710,
    name: "social-auth"
  }, {
    path: "/terms-and-conditions",
    component: _070c15c2,
    name: "terms-and-conditions"
  }, {
    path: "/visas-and-restrictions",
    component: _199c4aa0,
    name: "visas-and-restrictions"
  }, {
    path: "/wheel-of-fortune",
    component: _656a01a5,
    name: "wheel-of-fortune"
  }, {
    path: "/why-choose-us",
    component: _1d8a15d7,
    name: "why-choose-us"
  }, {
    path: "/c/mountains-in-mexico",
    component: _4de1699c,
    name: "c-mountains-in-mexico"
  }, {
    path: "/landing/tours-en-vancouver-con-vuelos-desde-guadalajara",
    component: _b5304faa,
    name: "landing-tours-en-vancouver-con-vuelos-desde-guadalajara"
  }, {
    path: "/landingz/caza-de-auroras-grupo-en-espanol",
    component: _4a9870de,
    name: "landingz-caza-de-auroras-grupo-en-espanol"
  }, {
    path: "/f/components/availability",
    component: _2f4292d6,
    name: "f-components-availability"
  }, {
    path: "/f/components/availability/components/card",
    component: _57761426,
    name: "f-components-availability-components-card"
  }, {
    path: "/user/profile/:tab?",
    component: _4e6d5248,
    name: "user-profile-tab"
  }, {
    path: "/user/reservation/:code?/edit",
    component: _6d9bcd5c,
    name: "user-reservation-code-edit"
  }, {
    path: "/public/reservation/:code?/:tab?",
    component: _72de9a6c,
    name: "public-reservation-code-tab"
  }, {
    path: "/user/reservation/:code?/:tab?",
    component: _3e140828,
    name: "user-reservation-code-tab"
  }, {
    path: "/announcement/:slug",
    component: _1b3113ea,
    name: "announcement-slug"
  }, {
    path: "/f/:permalink?",
    component: _08f0145d,
    name: "f-permalink"
  }, {
    path: "/h/:permalink?",
    component: _67d85fca,
    name: "h-permalink"
  }, {
    path: "/invitation/:referral_code?",
    component: _6ad0ac8e,
    name: "invitation-referral_code"
  }, {
    path: "/l/:permalink?",
    component: _525b4797,
    name: "l-permalink"
  }, {
    path: "/magic-link/:token?",
    component: _34a29417,
    name: "magic-link-token"
  }, {
    path: "/outlet/:test",
    component: _bbb7ecd2,
    name: "outlet-test"
  }, {
    path: "/p/:slug?",
    component: _400026b3,
    name: "p-slug"
  }, {
    path: "/permalink/:slug?",
    component: _57ad936e,
    name: "permalink-slug"
  }, {
    path: "/landing/:permalink?/:test?",
    component: _26f8912e,
    name: "landing-permalink-test"
  }, {
    path: "/",
    component: _540d2ada,
    name: "index"
  }, {
    path: "/:influencer/aventura-rio-nilo",
    component: _1158b032,
    name: "influencer-aventura-rio-nilo"
  }, {
    path: "/:influencer/explorando-turquia-con-vuelo",
    component: _87a4ecd4,
    name: "influencer-explorando-turquia-con-vuelo"
  }, {
    path: "/:influencer/lo-mejor-peru",
    component: _31e44d8f,
    name: "influencer-lo-mejor-peru"
  }, {
    path: "/:influencer/noches-auroras-boreales-con-vuelo",
    component: _42c7805c,
    name: "influencer-noches-auroras-boreales-con-vuelo"
  }, {
    path: "/:influencer/salkantay-trek",
    component: _072fef8c,
    name: "influencer-salkantay-trek"
  }, {
    path: "/:influencer/santorini-naxos-y-atenas-en-una-aventura-por-grecia",
    component: _6919b56d,
    name: "influencer-santorini-naxos-y-atenas-en-una-aventura-por-grecia"
  }, {
    path: "/:type(continent|city|category|continente|ciudad|categoria)/:value/:tab(trips|tours|viajes|tours)?/:days?",
    component: _4d51ec60,
    name: "search-type"
  }, {
    path: "/:type(country|pais)/:travelTo(travel-to-|viajar-a-):value",
    component: _388a53b1,
    name: "search-country"
  }, {
    path: "/:type(checkout|layaway|fast-checkout)",
    component: _a5884c36,
    name: "checkout-layaway"
  }, {
    path: "/:type(tours|paquete)/:permalink/:test(ppcv1)",
    component: _054a5749,
    name: "product-permalink-ppcv1"
  }, {
    path: "/:type(tours|paquete)/:permalink",
    component: _437551fd,
    name: "product-permalink"
  }, {
    path: "/:type(general-phocuswright-2022|phochuswright-2022-travel-tech-leaders|phocuswright-2022-invite-only)",
    component: _2f558d49,
    name: "phocuswright"
  }, {
    path: "/expo-viaja-vuela-2023",
    component: _e5a8ce66,
    name: "expo-viaja-vuela-2023"
  }, {
    path: "*",
    redirect: "/404"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
